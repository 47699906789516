import { graphql } from 'gatsby'
import { pick } from 'lodash'

import BaseEntry from './base-entry'

const ProjectsEntry = ({ data, ...props }) => (
  <BaseEntry
    seo={(story) => {
      const { search } = props.location
      const { default_view = 'list', default_tag = 'all' } = story.content ?? {}
      const indexedPath = !!(search.includes(default_view) && search.includes(default_tag))

      return {
        noindex: !indexedPath,
        nofollow: !indexedPath,
      }
    }}
    pagination={{ ...pick(props.pageContext, ['limit', 'totalPages', 'childComponent']), data: data.projects.edges }}
    {...props}
  />
)

export const projectsQuery = graphql`
  query getProjects($limit: Int!, $childComponent: String!) {
    projects: allStoryblokEntry(filter: { field_component: { eq: $childComponent } }, limit: $limit) {
      edges {
        node {
          field_component
          tag_list
          name
          full_slug
          content
          uuid
        }
      }
    }
  }
`

export default ProjectsEntry
